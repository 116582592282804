<template>
  <div>
    <div :class="{frmBlock__layout: list.length > 0}">
      <draggable
        :list="list"
        delayOnTouchOnly="true"
        ghostClass="DAV-block__item--active"
        handle=".DAV-block__supports--move"
        animation="200"
        delay="100"
        class="draggable-wrap"
        @end="updateOption()"
      >
        <div
          v-for="(item, index) in list"
          :key="index"
          class="DAV-block__item"
          :class="{'DAV-block__item--hide': !item.enabled}"
        >
          <div class="DAV-block__bullet">
            <img v-if="item.imgURL" class="DAV-block_img" :src="item.imgURL" />
            <img
              v-else
              class="DAV-block_img DAV-block_img_no"
              src="@/assets/images/common/icon_logo_grey.svg"
            />
            <span>컴포넌트 숨김</span>
          </div>
          <button class="DAV-block__name" @click="openPopupDetail(index, item)">
            {{ item.text }}
          </button>
          <div class="DAV-block__supports">
            <button
              class="DAV-block__supports--move"
              oncontextmenu="return false"
            >
              이동
            </button>
            <button
              class="DAV-block__supports--option"
              @click="toggleOption(index, true)"
            >
              컴포넌트 옵션 열기
            </button>
          </div>
          <transition name="slide" mode="out-in">
            <div class="DAV-block__options" v-show="optionVisible[index]">
              <button
                class="DAV-block__options--delete"
                @click="imgDelete(index)"
              >
                <span>컴포넌트 삭제</span>
              </button>
              <button
                class="DAV-block__options--display"
                @click="imgEnabled(index, !item.enabled)"
              >
                <span>컴포넌트 숨김</span>
              </button>
              <button
                class="DAV-block__options--close"
                @click="toggleOption(index, false)"
              >
                <span>컴포넌트 옵션 닫기</span>
              </button>
            </div>
          </transition>
        </div>
      </draggable>
    </div>
    <div class="add-button" @click="openPopupDetail()">
      {{ list.length ? `+ 추가(${list.length}/${displayLength})` : `+ 추가` }}
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {createNamespacedHelpers} from "vuex";

const ModuleAttach = createNamespacedHelpers("ModuleAttach");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  components: {draggable},
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      optionVisible: {},
      list: []
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties
        : this.option.value;
    },
    displayLength() {
      return this.option.componentOption.listMaxLength > 99
        ? 99
        : this.option.componentOption.listMaxLength;
    }
  },
  mounted() {
    this.imgLoad();

    if (this.$route.path === "/option") {
      let temp = {};
      this.initialValue.map(v => {
        temp[v.key] = v.initialValue;
      });
      // temp["imgURL"] = ["", ""];

      temp.img.map((v, index) => {
        this.list.push({
          img: v,
          alt: temp.alt[index],
          text: temp.text[index],
          url: temp.url[index],
          target: temp.target[index],
          enabled: temp.enabled[index],
          // imgURL: temp.imgURL[index]
          imgURL: ""
        });
      });
      this.updateProperty(
        temp.img,
        temp.alt,
        temp.text,
        temp.url,
        temp.target,
        temp.enabled
      );
    } else {
      this.initialValue.img.map((v, index) => {
        this.list.push({
          img: v,
          alt: this.initialValue.alt[index],
          text: this.initialValue.text[index],
          url: this.initialValue.url[index],
          target: this.initialValue.target[index],
          enabled: this.initialValue.enabled[index],
          imgURL: ""
        });
      });
    }
  },
  created() {
    let id =
      this.$route.path === "/option"
        ? this.option.componentOptionId
        : this.option.id;

    this.$EventBus.$on(`MultiTextImageURLUpdateValue${id}`, (v, i) => {
      const list = [...this.list];
      list[i] = v;
      this.list = list;
      this.updateOption();
    });
    this.$EventBus.$on(`MultiTextImageURLUpdateImg${id}`, (img, i, status) => {
      this.list[i].img = img;
      this.updateOption();
      if (status === "select") {
        this.openPopupDetail(i, this.list[i]);
        if (img.substr(0, 1) === "p") this.partnerImageRead(img, i);
        if (img.substr(0, 1) === "c") this.componentImageRead(img, i);
        return;
      }
      if (status === "cancel") {
        this.openPopupDetail(i, this.list[i]);
        return;
      }
    });
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(img, alt, text, url, target, enabled) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: img
          },
          {
            id: this.option.componentOption.properties[1].id,
            initialValue: alt
          },
          {
            id: this.option.componentOption.properties[2].id,
            initialValue: text
          },
          {
            id: this.option.componentOption.properties[3].id,
            initialValue: url
          },
          {
            id: this.option.componentOption.properties[4].id,
            initialValue: target
          },
          {
            id: this.option.componentOption.properties[5].id,
            initialValue: enabled
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption() {
      const img = [];
      const alt = [];
      const text = [];
      const url = [];
      const target = [];
      const enabled = [];
      this.list.map(i => {
        img.push(i.img);
        alt.push(i.alt);
        text.push(i.text);
        url.push(i.url);
        target.push(i.target);
        enabled.push(i.enabled);
      });

      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        img
      );
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[1].key,
        alt
      );
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[2].key,
        text
      );
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[3].key,
        url
      );
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[4].key,
        target
      );
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[5].key,
        enabled
      );

      if (this.$route.path === "/option") {
        this.updateProperty(img, alt, text, url, target, enabled);
      }
    },
    // 컴포넌트 설정 toggle
    toggleOption(index, value) {
      this.optionVisible = {};
      this.$set(this.optionVisible, index, value);
    },
    // 입력 팝업 이동
    openPopupDetail(i, v) {
      if (i === undefined) {
        if (this.list.length >= this.displayLength) {
          this.setAlert({
            isVisible: true,
            message: `더 이상 추가할 수 없습니다.`
          });
          return;
        }
        this.list.push({
          img: "",
          alt: "",
          text: "",
          url: "",
          target: "_self",
          enabled: true,
          imgURL: ""
        });
      }

      this.$store.commit("ModuleEditor/setPopup", {
        page: "ComponentMultiTextImageURL",
        index: i ?? this.list.length - 1,
        value: v ?? {
          img: "",
          alt: "",
          text: "",
          url: "",
          target: "_self",
          enabled: true,
          imgURL: ""
        },
        option: this.option
      });
    },
    // img 삭제
    imgDelete(i) {
      this.optionVisible = {};
      this.list.splice(i, 1);
      this.updateOption();
    },
    // img 숨김 처리
    imgEnabled(i, v) {
      this.optionVisible = {};
      this.list[i].enabled = v;
      this.updateOption();
    },
    // img 다운
    imgLoad() {
      let isOption = this.$route.path === "/option" ? true : false;
      let _root = isOption ? this.initialValue : this.initialValue.img;

      _root.map((value, index) => {
        if (!isOption) {
          if (value !== "" && value.substr(0, 1) === "p")
            this.partnerImageRead(value, index);
          if (value !== "" && value.substr(0, 1) === "c")
            this.componentImageRead(value, index);
        } else {
          const _key = value.key;
          if (_key !== "img") return false;

          const _value = value.initialValue;
          _value.map((v, idx) => {
            if (v !== "" && v.substr(0, 1) === "p")
              this.partnerImageRead(v, idx);
            if (v !== "" && v.substr(0, 1) === "c")
              this.componentImageRead(v, idx);
          });
        }
      });
    },
    // 컴포넌트 이미지 상세
    componentImageRead(id, index) {
      let payload = {};
      payload.id = id.substr(1);

      this.actComponentImageRead(payload).then(response => {
        this.list[index].imgURL = response.data.thumb?.url;
      });
    },
    // 파트너 이미지 상세
    partnerImageRead(id, index) {
      let payload = {};
      payload.id = id.substr(1);

      this.actPartnerImageRead(payload).then(response => {
        this.list[index].imgURL = response.data.thumb?.url;
      });
    },
    ...ModuleAttach.mapActions([
      "actComponentImageRead",
      "actPartnerImageRead"
    ]),
    ...ModuleAlert.mapMutations(["setAlert"])
  }
};
</script>

<style lang="scss" scoped>
.frmBlock__layout {
  margin-bottom: 24px;
}

.draggable-wrap {
  width: 100%;

  .DAV-block {
    &__name {
      flex-grow: 1;
      text-align: left;
      width: 100%;
      height: 100%;
      @include text-ellipsis;
    }
  }
  .DAV-block__item {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    margin-top: 8px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    @include box;

    &:first-child {
      margin-top: 0px;
    }

    &--active {
      z-index: 1 !important;
      border: 0 !important;
      box-shadow: 0px 0px 8px #54c7a2 !important;

      & .DAV-block__supports--move {
        background-image: url($path + "ico_move_active.svg");
      }
    }
    &--hide {
      .DAV-block__bullet span {
        display: block;
      }
      .DAV-block__options--display span {
        background-image: url($path + "ico_opt_display_show.svg");
      }
    }
  }
  .DAV-block__bullet {
    flex-shrink: 0;
    position: relative;
    width: 40px;
    height: 40px;
    margin: 0 16px;
    background-color: #f0f0f0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-color: rgba(63, 63, 63, 0.4);
      background-image: url($path + "ico_opt_display_hide.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 16px auto;
      text-indent: -9999px;
    }

    .DAV-block_img {
      width: 40px;
      height: 40px;
      border-radius: 12px;
      object-fit: cover;
    }

    .DAV-block_img_no {
      width: 25px;
      height: 25px;
    }
  }
  .DAV-block__supports {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    margin-right: 8px;
    margin-left: auto;
    width: 81px;
    height: 24px;
    background-image: url($path + "bg_vline.svg");
    background-repeat: no-repeat;
    background-position: 50% 0;

    &--move {
      width: 24px;
      height: 24px;
      background-image: url($path + "ico_move.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-indent: -9999px;
      cursor: pointer;
      -webkit-touch-callout: none;
    }
    &--option {
      width: 24px;
      height: 24px;
      background-image: url($path + "bul_arr_next.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      text-indent: -9999px;
    }
  }
  .DAV-block__options {
    z-index: 1;
    display: flex;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 152px;
    background-color: #985fe8;
    border-radius: 8px;

    button {
      float: left;
      height: 100%;
      background-image: url($path + "bg_vline_purple.svg");
      background-repeat: no-repeat;
      background-position: 0 50%;
      span {
        display: block;
        height: 100%;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
      &:first-child,
      &:last-child {
        background: 0;
      }
    }
    &--delete {
      width: 35%;

      span {
        background-image: url($path + "ico_opt_delete.svg");
      }
    }
    &--display {
      width: 35%;

      span {
        background-image: url($path + "ico_opt_display_hide.svg");
      }
    }
    &--close {
      width: 30%;

      span {
        background-color: #484948;
        background-image: url($path + "ico_opt_close.svg");
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
}

.add-button {
  height: 56px;
  border: 2px dashed #d8dae5;
  border-radius: 8px;
  color: #797979;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
  box-sizing: border-box;
}
</style>
